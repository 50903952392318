import { Box, Heading, Stack, StackDivider, Text } from "@chakra-ui/react";
import React from "react";
import { Head } from "../../components/Head";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { SharedHero } from "../../components/shared/SharedHero";

const TrasparenzaPage: React.FC = () => {
  return (
    <Layout>
      <Head title="Note legali" />

      <SharedHero />

      <Section bg={"utils.white"}>
        <Box>
          <Stack
            spacing={8}
            direction={"column"}
            divider={<StackDivider borderColor="utils.gray.02" />}
          >
            <Stack spacing={4} direction={"column"} textAlign={"justify"}>
              <Heading
                as={"h1"}
                fontSize={["24px", "32px"]}
                fontWeight={"400"}
                textAlign={"left"}
                fontFamily={"body"}
                color={"accent.01"}
              >
                Note Legali
              </Heading>
              <Text>
                Il presente Sito Internet è il sito ufficiale di HCBE.
              </Text>
              <Text>
                Consente ai clienti o ai potenziali clienti di conoscere le
                informazioni sulla società e sul gruppo bancario cui HCBE
                appartiene, i prodotti offerti, le loro caratteristiche e gli
                strumenti di trasparenza e la possibilità di usufruire dei
                propri servizi finanziari online.
              </Text>
              <Text>
                Si raccomanda, pertanto, un'attenta lettura ed eventualmente
                anche la stampa dei relativi documenti.
              </Text>
              <Text>
                Il Sito, in conformità agli strumenti tecnologici ora
                disponibili, esclude accessi non autorizzati e garantisce la
                riservatezza delle informazioni fornite dai clienti o dai
                potenziali clienti, i quali sono responsabili del loro utilizzo.
                In ogni caso, HCBE non si assume alcuna responsabilità per gli
                eventuali problemi derivanti dall'utilizzo del presente sito o
                su siti ad esso collegati.
              </Text>
              <Text>
                I collegamenti esterni accessibili dal presente sito non sono
                sotto il controllo di HCBE, e la stessa non è pertanto
                responsabile del relativo contenuto ovvero di eventuali
                modifiche o aggiornamenti ai suddetti siti. I dati personali
                forniti dai clienti o dai potenziali clienti solo per gli scopi
                per i quali sono stati raccolti.
              </Text>
            </Stack>

            <Stack spacing={4} direction={"column"} textAlign={"justify"}>
              <Heading
                as="h5"
                size="md"
                fontWeight={"400"}
                textAlign={"left"}
                fontFamily={"body"}
                color={"accent.01"}
              >
                Copyright e Marchi
              </Heading>
              <Text>
                Il Sito internet, così come i marchi e i loghi in esso presenti,
                appartengono ai legittimi proprietari.
                <br />
                Il contenuto del Sito è protetto da copyright per cui non può
                essere copiato o riprodotto senza il consenso scritto di HCBE. È
                altresì vietato l'utilizzo dei marchi e dei loghi senza la
                preventiva autorizzazione scritta dei legittimi proprietari.
              </Text>
            </Stack>

            <Stack spacing={4} direction={"column"} textAlign={"justify"}>
              <Heading
                as="h5"
                size="md"
                fontWeight={"400"}
                textAlign={"left"}
                fontFamily={"body"}
                color={"accent.01"}
              >
                Trattamento dei Dati Personali
              </Heading>
              <Text>
                HCBE tratta i dati personali dei clienti o dei potenziali
                clienti nel pieno rispetto delle disposizioni vigenti in materia
                di privacy (Regolamento UE 2016/679 - GDPR).
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Section>
    </Layout>
  );
};

export default TrasparenzaPage;
